// 亦中学子
<template>
  <div class="campusLife">
    <TabPageLayout activeCode="xiaoyuanshenghuo">
      <div class="container internationalCourse">
        <div class="div_mod div_mod2 div_mod1">
          <div class="div_tit" v-for="block in blocks" :key="block.id">
            <h2>{{ block.title }}</h2>
          </div>
        </div>

        <div class="news">
          <div class="news_list">
            <ul>
              <li v-for="article in blockArticles[0]" :key="article.id">
                <ArticleCard :article="article" activeCode="xiaoyuanshenghuo" />
              </li>
            </ul>
          </div>
        </div>

        <el-pagination
          class="div_page"
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :total="+total"
          :current-page="+currentPage"
          :page-size="pageSize"
          :hide-on-single-page="true"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </TabPageLayout>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TabPageLayout from './components/TabPageLayout.vue'
import { useGetCategories } from './utils/useGetCategories'
import portalApi from '../../../lvya-common/src/api/eos/portal'
import ArticleCard from './components/ArticleCard.vue'

export default {
  components: { TabPageLayout, ArticleCard },
  computed: {
    ...mapState({
      nav: (state) => state.yzsyStore.nav,
    }),
    typeId() {
      return (
        this.nav?.find((item) => item.code === 'xiaoyuanshenghuo')?.stypes[2]
          ?.id || undefined
      )
    },
    schoolYear() {},
  },
  data() {
    return {
      blocks: [],
      blockArticles: [],
      total: 0,
      currentPage: 1,
      pageSize: 9,
    }
  },
  methods: {
    async getBlocks() {
      const categories = await useGetCategories(this.typeId)
      console.log({ categories })
      this.blocks = categories.list
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.getArticles()
    },
    async getArticles() {
      // const portalSchoolInfo = JSON.parse(
      //   localStorage.getItem('portalSchoolInfo')
      // )
      const articles = []
      for (const block of this.blocks) {
        console.log(block, 'block')
        let reqData = {
          noSchoolId: true,
          schoolId: '7',
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          typeId: block.id,
          isContent: 'Y',
          applyState: 'Y',
        }
        let resData = await portalApi.getArtsByPage(reqData)
        console.log(resData, 'articles')
        articles.push(resData?.list)
        console.log(articles, 'articles')
        this.total = resData.total
      }
      this.blockArticles = articles
    },
  },
  async mounted() {
    await this.getBlocks()
    await this.getArticles()
  },
}
</script>

<style lang="less" scoped>
@import "./style/common.css";
@import "./style/global.css";
@import "./style/campusLife.css";
</style>

